import { createStore } from 'vuex'

import user from './modules/user';
import layout from './modules/layout';

const store = createStore({

    state:{
     },
     getters:{
       
     },
     mutations:{
        
     },
     actions:{
 
     },
     modules:{
         user,
         layout,
     },

})

export default store

   
    
























