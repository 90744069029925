const axios = require('axios');
axios.defaults.withCredentials = true;
const instance = axios.create({ baseURL: 'https://api.cpl-saint-christophe.com' });
// const instance = axios.create({ baseURL: 'http://localhost/school_man/backend/public' });

export default {
    state: {
        user: {},
        selectedYear: null,
        data: 'mon store fonctionne encore',
        authenticated: null,
        message: "hello word",
    },
    getters: {
        helloWord: state => state.message,
        getloggedUser: state => state.user,
        getUserState: state => state.authenticated,
        selectedYear: state => state.selectedYear,
    },
    mutations: {
        SET_USER_STATUS(state, authenticated) {
            state.authenticated = authenticated;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        CHANGE_YEAR(state, selectedYear) {
            state.selectedYear = selectedYear;
        }
    },

    actions: {
        changeSchoolYear({ commit }, { selectedYear }) {
            commit('CHANGE_YEAR', selectedYear);
        },
        setLoggedUser({ commit }, { user }) {
            commit('SET_USER', user)
        },
        changeUserStatus({ commit }, { authenticated }) {
            commit('SET_USER_STATUS', authenticated);
        },
        register(commit, data) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.post('/register', data)
                            .then(function (response) {
                                resolve(response);
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    });
            });
        },

        login(commit, data) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.post('/login', data)
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },

        logout() {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.post('/logout')
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },

        user() {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.get('/api/user')
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },

        postRequest(commit, { route, data }) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.post(route, data)
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },

        putRequest(commit, { route, data }) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.put(route, data)
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },


        deleteRequest(commit, { route, data }) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.delete(route, data)
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },

        mediaGetRequest(commit, { route, data }) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.get(route, {
                            params: data,
                            responseType: 'blob' 
                        })
                        .then(function (response) {
                            resolve(response)
                        })
                        .catch(function (error) {
                            reject(error);
                        });
                    })
            })
        },
        

        getRequest(commit, { route, data }) {
            return new Promise((resolve, reject) => {
                instance.get('/sanctum/csrf-cookie')
                    .then(response => {
                        console.log(response);
                        instance.get(route, data)
                            .then(function (response) {
                                resolve(response)
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
            })
        },

    },



























}